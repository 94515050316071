import {Vue, router, store, icons} from './common'
import App from './App'
import MessageManager from "@/domain/core/utils/messageManager";

MessageManager.setParams({userId: 'anonymous'})

new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',
    components: {
        App
    }
})
